import { FeatureFlags } from '../entities/feature-flag';
import { Environment } from './types';

export const environment: Environment = {
  env: 'production',
  firebaseConfig: {
    apiKey: 'AIzaSyCFNwhCIo5RIGkTAlq5vb4TIjC4CTaB51s',
    authDomain: 'distribute-b4a4d.firebaseapp.com',
    projectId: 'distribute-b4a4d',
    storageBucket: 'distribute-b4a4d.appspot.com',
    messagingSenderId: '10257614875',
    appId: '1:10257614875:web:d671df36b813090db810be',
  },
  sentryDsn:
    'https://c80cc6a6b21c49378ec0dcb7e9a1e3e0@o4504050707726336.ingest.sentry.io/4504050731057152',
  apiUrl: 'https://api.distribute.so/api',
  dataProcessingApiUrl: 'https://data-processing-api.distribute.so',
  staticBaseUrl: 'https://storage.distribute.so',
  staticBaseTranscoderUrl:
    'https://storage.googleapis.com/distribute-prod-transcoder-storage',
  appOrigin: 'distribute.so',
  appHost: 'app.distribute.so',
  segmentWriteKey: 'Ei6qaLjR9TNDydA1nESSxjQ5aWK4WVr5',
  templateSubmitLink: 'https://survey.typeform.com/to/vqZFKWvO',
  gleapApiKey: 'fyjQVBxJ9EFuC6KEEUPb6v26U3MeHvI9',
  featureFlags: {
    [FeatureFlags.ZAPIER]: true,
    [FeatureFlags.DISTRIBUTE_TAB]: true,
    [FeatureFlags.PERSONALIZED_LINKS]: false,
    [FeatureFlags.SNIPPETS]: true,
    [FeatureFlags.HTML_CONTENT]: true,
    [FeatureFlags.DOCUMENT_COLLABORATION]: true,
    [FeatureFlags.NARRATION]: true,
    [FeatureFlags.CONTACT_CARD]: true,
  },
  stripePublishableKey:
    'pk_live_51Lz0TbDGP8bK7ki51JOlV2e7PGxC8l9rxRKSnjkc0oIskCVeMTB3qAnfp1wXpMej56z3ExH70OwztygkZK8hTCYX00Mqc65KNz',
  landingUrl: 'https://distribute.so',
  linkedinClientId: '78hlzil5g8mbva',
  extensionId: 'mmfiaikmjhpggojgalplfnlplbkglbjc',
  extensionLocalId: '',
  extensionWebsiteUrl:
    'https://chromewebstore.google.com/u/0/detail/distribute-screen-recorde/mmfiaikmjhpggojgalplfnlplbkglbjc',
  clearbitApiUrl: 'https://autocomplete.clearbit.com/v1/companies/suggest',
  gongClientId: '4mee7r4x',
  collaborationServerUrl: 'production.distribute-collaboration.com',
};
